"use client";

import React, { useEffect, useState } from "react";

const validTabs = ["#whats-edgo", "#why-edgo", "#how-it-works", "#rewards"];

export function LandingPageTabs() {
  const [activeTab, setActiveTab] = useState("#whats-edgo");
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const handleHashChange = () => {
      if (initialLoad) {
        // Prevent scrolling or URL manipulation on initial load
        setInitialLoad(false);
        return;
      }

      const hash = window.location.hash;
      if (validTabs.includes(hash)) {
        setActiveTab(hash);
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        setActiveTab("#whats-edgo");
        window.history.replaceState(null, null, "#whats-edgo");
        const element = document.querySelector("#whats-edgo");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    // Add event listener for hash changes
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [initialLoad]);

  const handleClick = (e, href) => {
    e.preventDefault();
    if (href && href !== activeTab) {
      // Ensure href is not empty
      setActiveTab(href);
      window.history.pushState(null, null, href);
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const isActive = (href) => {
    return activeTab === href
      ? "border-[#264FFA] text-[#264FFA]"
      : "text-[10px]";
  };

  return (
    <div className="mx-auto sm:px-2 lg:px-8 w-full bg-[#F2F2F2] shadow">
      <div className="flex justify-between h-16 w-full">
        <div className="flex w-full sm:px-0 lg:px-[100px] items-center sm:items-stretch">
          <div className="flex w-full justify-between">
            <a
              href="#whats-edgo"
              onClick={(e) => handleClick(e, "#whats-edgo")}
              className={`inline-flex items-center border-b-2 px-2 py-1 text-[10px] sm:text-base font-medium flex-shrink-0 ${isActive(
                "#whats-edgo"
              )}`}
            >
              What’s EdGo?
            </a>
            <a
              href="#why-edgo"
              onClick={(e) => handleClick(e, "#why-edgo")}
              className={`inline-flex items-center border-b-2 px-2 py-1 text-[10px] sm:text-base lg:text-md font-medium flex-shrink-0 ${isActive(
                "#why-edgo"
              )}`}
            >
              Why EdGo?
            </a>
            <a
              href="#how-it-works"
              onClick={(e) => handleClick(e, "#how-it-works")}
              className={`inline-flex items-center border-b-2 px-2 py-1 text-[10px] sm:text-base font-medium flex-shrink-0 ${isActive(
                "#how-it-works"
              )}`}
            >
              How does it work?
            </a>
            <a
              href="#rewards"
              onClick={(e) => handleClick(e, "#rewards")}
              className={`inline-flex items-center border-b-2 px-2 py-1 text-[10px] sm:text-base font-medium flex-shrink-0 ${isActive(
                "#rewards"
              )}`}
            >
              Any other benefits?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
