import React from "react";

interface ContainerProps {
  children?: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  return (
    <div className="mx-auto max-w-7xl p-5 sm:p-6 md:p-8 lg:p-8">{children}</div>
  );
};

export { Container };
