"use client";
import { forwardRef, useCallback, useState } from "react";
import { IInputProps } from "./i-input";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { HeyWowFont } from "src/fonts/fonts-config";

const FloatingLabelInput = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      id,
      name,
      type = "text",
      label,
      value,
      error,
      endAdornment,
      onChange,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleChange = useCallback(
      (event: any) => {
        onChange?.(event);
      },
      [onChange]
    );

    return (
      <div className={`${HeyWowFont.className} relative [#f5fffb]`}>
        <div className="relative">
          <input
            ref={ref}
            value={value}
            name={name}
            id={id}
            type={showPassword ? "text" : type}
            onChange={handleChange}
            className="px-2.5 pb-2.5 pt-3 w-full text-md text-gray-900 rounded-lg border border-1 border-[#666666] focus:outline-none  focus:ring-0 focus:border-gray-400 peer bg-[#f5fffb]"
            placeholder=""
            {...rest}
          />
          <label
            htmlFor={id}
            className="absolute text-md text-[#666666]  duration-300 transform -translate-y-4 scale-75 top-2 z-5 origin-[0] px-2 peer-focus:px-3 peer-focus:text-[#666666] peer-focus:text-lg  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1  bg-[#f5fffb] "
          >
            {label}
          </label>
        </div>

        {error && <p className="text-xs text-red-500">{error}</p>}
        <div
          className={`absolute inset-y-0 right-0 ${
            error && "bottom-4"
          } flex items-center pr-3`}
        >
          {endAdornment && (
            <div>
              {showPassword ? (
                <EyeSlashIcon
                  className="h-5 w-5 text-gray-400 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <EyeIcon
                  className="h-5 w-5 text-gray-400 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

FloatingLabelInput.displayName = "base-input";

export { FloatingLabelInput };
