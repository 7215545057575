import React from "react";

interface GridListProps {
  children: React.ReactNode;
  className: string;
}

const GridList = ({ children, className }: GridListProps) => {
  return (
    <ul role="list" className={`grid gap-6 ${className}`}>
      {React.Children.map(children, (child, index) => (
        <li key={index} className="col-span-1">
          {child}
        </li>
      ))}
    </ul>
  );
};

export { GridList };
