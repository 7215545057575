"use client";

import React, { useState, useCallback, useEffect } from "react";

interface AnimatedTextProps {
  description: string;
  textLarge: boolean;
  delay: number;
  classes?: string;
}

const AnimatedText = ({
  description,
  textLarge,
  delay,
  classes,
}: AnimatedTextProps) => {
  const [text, setText] = useState("");

  const animateText = useCallback(
    (direction: any) => {
      const max = description?.length;

      for (let index = 0; index <= max; index++) {
        const startIndex = direction ? 0 : max - 1;
        const endIndex = direction ? index : max - index;
        setTimeout(() => {
          setText(description?.substring(startIndex, endIndex));
        }, index * 70);
      }
    },
    [description]
  );

  useEffect(() => {
    const delayAnimation = setTimeout(() => {
      animateText(true);
    }, delay);

    return () => clearTimeout(delayAnimation);
  }, [animateText]);

  return (
    <p
      className={`${
        textLarge
          ? "text-3xl md:text-2xl lg:text-[2.5em] font-bold sm:leading-tight md:leading-tight lg:leading-tight"
          : "text-base"
      } ${classes}`}
    >
      {text.includes("EdGo") ? (
        <>
          {text.substring(0, text.indexOf("EdGo"))}
          <span className="text-[#264FFA]">EdGo</span>
          {text.substring(text.indexOf("EdGo") + 4)}
        </>
      ) : (
        text
      )}
    </p>
  );
};

export { AnimatedText };
