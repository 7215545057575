"use client";

import React from "react";
import { CheckIcon } from "@heroicons/react/24/outline";

import { Container } from "../../container";
import BaseButton from "../../button/base-button";
import { useRouter } from "next/navigation";

const FifthSection = () => {
  const router = useRouter();

  const Icon = (
    <CheckIcon className="block h-5 w-5 text-green-500" aria-hidden="true" />
  );

  const items = [
    {
      icon: Icon,
      text: "We ensure you receive your investment account balance when your policy reaches its end date",
    },
    {
      icon: Icon,
      text: "We make sure you get your savings back when your policy is all done.",
    },
  ];

  return (
    <Container>
      <div
        id="rewards"
        className="flex items-center flex-col sm:flex-row md:flex-row lg:flex-row w-[100%] text-[#000000] mb-10"
      >
        <div className="sm:w-[100%] md:w-[100%] lg:w-[55%]">
          <img src="/images/fifthsc.png" alt="" />
        </div>
        <div className="sm:w-[100%] md:w-[100%] lg:w-[45%]">
          <h1 className="text-3xl md:text-2xl lg:text-[2.5em] font-bold mb-5 sm:leading-tight md:leading-tight lg:leading-tight">
            What if nothing <br /> happens to you when <br /> your savings
            mature?
          </h1>
          <ul>
            {items?.map((fx, idx) => (
              <li key={idx} className="flex gap-3 items-center">
                {fx.icon} <p>{fx.text}</p>
              </li>
            ))}
          </ul>
          <div className="w-full my-10 flex justify-center sm:flex sm:justify-start">
            <BaseButton
              onClick={() => router.push("/sign-up")}
              extraPadding="px-10"
            >
              Get started
            </BaseButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export { FifthSection };
