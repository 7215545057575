"use client";
import { forwardRef, useCallback, useState } from "react";
import { IInputProps } from "./i-input";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { HeyWowFont } from "src/fonts/fonts-config";

const BaseInput = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      id,
      name,
      type = "text",
      label,
      value,
      placeholder,
      error,
      endAdornment,
      onChange,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleChange = useCallback(
      (event: any) => {
        onChange?.(event);
      },
      [onChange]
    );
    return (
      <div className={`${HeyWowFont.className} mb-2`}>
        <label className="mb-2 text-[16px]">{label}</label>
        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            ref={ref}
            value={value}
            name={name}
            id={id}
            onChange={handleChange}
            className="bg-[#f5fffb] w-full border border-1 border-black focus:border-transparent p-2 rounded-lg"
            type={showPassword ? "text" : type}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {endAdornment && (
              <div>
                {showPassword ? (
                  <EyeSlashIcon
                    className="h-5 w-5 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-400 cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {error && <p className="text-xs text-red-500">{error}</p>}
      </div>
    );
  }
);

BaseInput.displayName = "base-input";

export { BaseInput };
