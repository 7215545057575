import { forwardRef, useCallback } from "react";

import { IInputProps } from "./i-input";
import { HeyWowFont } from "src/fonts/fonts-config";

const TextArea = forwardRef<HTMLTextAreaElement, IInputProps>(
  (
    { id, name, type = "text", label, value, placeholder, error, onChange },
    ref
  ) => {
    const handleChange = useCallback(
      (event: any) => {
        onChange?.(event);
      },
      [onChange]
    );
    return (
      <div className={`${HeyWowFont.className}`}>
        <p className="mb-2 text-[16px]">{label}</p>
        <textarea
          ref={ref}
          onChange={handleChange}
          value={value}
          name={name}
          id={id}
          className="w-full h-24 border border-1 border-black focus:border-transparent p-2 rounded-lg resize-none"
          placeholder={placeholder}
        />
        {error && <p className="text-xs text-red-500">{error}</p>}
      </div>
    );
  }
);

TextArea.displayName = "text-area";

export { TextArea };
