export function commafy(numb: number | string, addCurrency?: boolean) {
  const num = Number(numb) / 100;
  if (addCurrency) {
    return `₦${num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function splitAndCapitalize(str: string, splitBy?: RegExp | string) {
  let words = str.split(splitBy || " ");

  let capitalizedWords = words
    .map((word) => word.toLowerCase())
    .map((word, i) =>
      i == 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word
    );
  // Join the capitalized words with spaces
  return capitalizedWords.join(" ");
}
