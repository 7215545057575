import React from "react";
import { Container } from "../../container";
import BaseButton from "../../button/base-button";

const FourthSection = () => {
  const items = [
    {
      title: "Save monthly, quarterly or yearly",
      desc: "You can conveniently plan, budget & track savings for your child's education on the platform.",
    },
    {
      title: "Access to top schools & resources",
      desc: "You easily have access to top schools and resources that meet your plans & budget (Optional)",
    },
    {
      title: "When life happens",
      desc: "In the event of death or accident, we take responsibility over your child's school fees based on your plans.",
    },
  ];
  return (
    <div id="how-it-works" className="bg-white px-5 text-[#000000]">
      <Container>
        <h1 className="text-3xl md:text-2xl lg:text-[2.5em] font-bold mb-5 sm:leading-tight md:leading-tight lg:leading-tight text-center">
          How EdGo works
        </h1>
        <p className="text-[16px] lg:text-[20px] text-center">
          EdGo is a savings product that helps you plan ahead for your child's
          education <br /> and guarantee such education with an embedded life
          insurance.
        </p>
        <div className="flex flex-col-reverse sm:flex-row md:flex-row lg:flex-row w-[100%] text-[#000000] mt-10">
          <div className="sm:w-[100%] md:w-[100%] lg:w-[60%]">
            <div className="flex flex-col">
              {items?.map((fx, idx) => (
                <div key={idx} className="flex gap-5 items-start my-4">
                  <div className="mt-1.5 flex justify-center flex-shrink-0 items-center rounded-full h-7 w-7 bg-[#264FFA] text-white font-bold border">
                    <p>{idx + 1}</p>
                  </div>
                  <div>
                    <h3 className="font-bold text-[25px]">{fx.title}</h3>
                    <p className="text-[16px] my-2">{fx.desc}</p>
                  </div>
                </div>
              ))}
              <div className="w-full flex-grow-0 flex justify-center sm:flex sm:justify-start">
                <BaseButton>Plan with EdGo AI</BaseButton>
              </div>
            </div>
          </div>
          <div className="sm:w-[100%] md:w-[100%] lg:w-[40%]">
            <img src="/images/tabone.png" alt="" />
          </div>
        </div>
      </Container>
      <div className="border border-b-1 border-[#D9D9D9] my-10"></div>
    </div>
  );
};

export { FourthSection };
