const QuickSavingsDetailSkeleton: React.FC = () => {
  const shimmer =
    "before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/60 before:to-transparent";

  return (
    <div className={`animate-slide-in`}>
      {/* <div className="mb-4">
          <div className="h-6 w-40 bg-gray-300 rounded"></div>
        </div> */}
      <div className="mb-8">
        <div
          className={` relative ${shimmer} h-40 bg-gray-300 rounded-lg mb-4`}
        ></div>
        <div className="flex justify-center space-x-2 mb-4">
          <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
          <div className="h-4 w-40 bg-gray-300 rounded"></div>
          <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-3">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="h-20 bg-gray-300 rounded"></div>
          ))}
        </div>
      </div>
      <div className="mb-8">
        <div className="flex justify-between mb-4">
          <div className="h-5 w-32 bg-gray-300 rounded"></div>
          <div className="h-5 w-40 bg-gray-300 rounded"></div>
        </div>
        {[...Array(3)].map((_, index) => (
          <div key={index} className="h-16 bg-gray-300 rounded mb-4"></div>
        ))}
      </div>
    </div>
  );
};

export { QuickSavingsDetailSkeleton };
