import { commafy } from "@/src/utils/format-text";
import moment from "moment";
import Link from "next/link";
import React from "react";
import { GoArrowDownLeft } from "react-icons/go";

interface TransactionProps {
  id: string;
  kidLastName: string;
  kidFirstName: string;
  time: string;
  amount: string;
  isLast?: boolean;
}

const Transaction = ({
  kidLastName,
  kidFirstName,
  time,
  amount,
  id,
}: TransactionProps) => {
  return (
    <div className="my-2 py-4 border-b border-[#F2F2F2] last:border-b-0">
      <Link
        className="w-full flex items-start justify-between text-[#434249] "
        href={"/transactions/" + id}
      >
        <div className="flex items-center gap-3">
          <div className="size-[40px] bg-[#F4F4F4] flex justify-center items-center rounded-full">
            <GoArrowDownLeft color="#00C008" size={22} />
          </div>
          <div>
            <h1 className="font-[500] text-[14px] capitalize">{`${kidFirstName} ${kidLastName}`}</h1>
            <p className="text-[#868686] text-[12px] font-[400]">
              {moment(time).format("h:mm:A Do MMM, YYYY")}
            </p>
          </div>
        </div>
        <h1 className="text-[14px] font-[600]">+N{commafy(amount)}</h1>
      </Link>
    </div>
  );
};

export { Transaction };
