"use client";

import { getToken } from "@/src/utils/storage";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

interface AuthProtectProps {
  children: React.ReactNode;
}

function AuthProtect({ children }: AuthProtectProps) {
  const router = useRouter();
  useEffect(() => {
    const token = getToken();
    if (token) {
      router.push("/home");
    }
  }, []);

  return <div>{children}</div>;
}

export { AuthProtect };
