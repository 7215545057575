import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { IoIosArrowForward } from "react-icons/io";

type ActionCardType = {
  type: string;
  title: string;
  subTitle: string;
  buttonText: string;
  url: string;
  ImageURL?: string;
};

export const ActionCard = ({ cardData }: { cardData: ActionCardType }) => {
  return (
    <div
      className={clsx(
        "p-5 mt-5 rounded-xl",
        cardData?.type === "profile"
          ? "bg-[#FDF9D8]"
          : cardData?.type === "child"
          ? "bg-[#EBEFFE]"
          : "bg-[#FDF9D8]"
      )}
    >
      <div className="flex justify-between items-end">
        <div className="space-y-4">
          <div className="space-y-1">
            <h1 className="text-[#091B66] font-bold">{cardData?.title}</h1>
            <p className="text-sm max-w-52">{cardData?.subTitle}</p>
          </div>

          <Link
            href={"/" + cardData.url}
            className="text-[#091B66] cursor-pointer  px-3 py-2 bg-white text-sm font-medium rounded-3xl inline-flex items-center  gap-3 mt-5"
          >
            {cardData?.buttonText} <IoIosArrowForward />
          </Link>
        </div>
        {cardData?.ImageURL && (
          <Image
            src={cardData?.ImageURL}
            width={100}
            height={100}
            alt={cardData?.buttonText || "action card image"}
          />
        )}
        {/* <img src="/icons/flash.png" alt="flash-icon" /> */}
      </div>
    </div>
  );
};
