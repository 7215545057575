export interface StatBoxProps {
  title: string;
  details: any;
}

const StatsBox = ({ title, details }: StatBoxProps) => {
  return (
    <div className="space-y-1 border border-1 border-[#DEDEDE] rounded-lg p-3  bg-white">
      <p className="text-[#666] text-xs">{title}</p>
      <h3 className="text-[#434249] text-sm font-semibold capitalize">
        {details}
      </h3>
    </div>
  );
};

export { StatsBox };
