import React, { useState, useEffect } from "react";

const TrustFundDetailsSkeleton = () => {
  return (
    <div className="space-y-6 mt-8">
      <div className="animate-pulse space-y-6">
        {/* Top summary section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Card 1 */}
          <div className="p-4 bg-gray-200 rounded-md h-24"></div>
          {/* Card 2 */}
          <div className="p-4 bg-gray-200 rounded-md h-24"></div>
          {/* Card 3 */}
          <div className="p-4 bg-gray-200 rounded-md h-24"></div>
        </div>

        <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-3 mt-10">
          <div className="col-span-1">
            <div className="flex flex-col gap-16 mt-5">
              {/* Start Date Card */}
              <div className="p-4 bg-gray-200 rounded-md h-24"></div>
              {/* End Date Card */}
              <div className="p-4 bg-gray-200 rounded-md h-24"></div>
              {/* Beneficiary Card */}
              <div className="p-4 bg-gray-200 rounded-md h-24"></div>
            </div>
          </div>
          <div className="col-span-2">
            <div className="bg-white rounded-md p-4 shadow-md">
              <div className="h-6 bg-gray-200  rounded mb-4"></div>
              <div className="space-y-4">
                {/* Transaction skeleton */}
                {[...Array(8)].map((_, index) => (
                  <div
                    className="flex justify-between items-center"
                    key={index}
                  >
                    <div className="flex items-center space-x-2">
                      <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
                      <div className="h-6 bg-gray-200 w-40 rounded"></div>
                    </div>
                    <div className="h-6 bg-gray-200 w-16 rounded"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TrustFundDetailsSkeleton };
