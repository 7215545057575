import React from "react";

interface DashboardHeaderProps {
  title: string;
  description?: string;
}

const DashboardHeader = ({ title, description }: DashboardHeaderProps) => {
  return (
    <div>
      <h2 className="text-[#091B66] text-[24px] font-bold">{title}</h2>
      <p className="text-[#666666] text-[16px]">{description}</p>
    </div>
  );
};

export { DashboardHeader };
