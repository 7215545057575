import React from "react";
import { BaseDropdown, BaseInput } from "src/components/inputs";
import { CenterModal } from "./modals";
import BaseButton from "./button/base-button";

interface CallBackModalProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

const CallBackModal = ({ isOpen, setIsOpen }: CallBackModalProps) => {
  return (
    <CenterModal open={isOpen} setOpen={setIsOpen} title="REQUEST A CALL BACK">
      <BaseInput label="First name" id="first_name" placeholder="Alfred" />
      <div className="my-2">
        <BaseInput label="Last name" id="last_name" placeholder="Bryan" />
      </div>
      <div className="my-2">
        <BaseInput label="Phone number" id="number" placeholder="102 445 677" />
      </div>
      <div className="w-full my-2">
        <BaseDropdown w="100%" label="Reason for request" />
      </div>
      <div className="relative flex items-start my-5">
        <div className="flex h-6 items-center">
          <input
            id="offers"
            aria-describedby="offers-description"
            name="offers"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor="offers" className="text-[16px] text-gray-900">
            I consent to being contacted.
          </label>
        </div>
      </div>
      <p className="text-[16px] my-3">
        By submitting your details you give consent to the processing of your
        personal information to EdGo and our Partners.
      </p>
      <div>
        <BaseButton fullWidth={true}>Submit</BaseButton>
      </div>
    </CenterModal>
  );
};

export { CallBackModal };
