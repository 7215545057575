"use client";
import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { GoKebabHorizontal } from "react-icons/go";

type KebabButtonProps = {
  options: any[];
  optionsFns: (() => void)[];
};

const RowDots = ({ options, optionsFns }: KebabButtonProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index: number) => {
    if (optionsFns[index]) {
      optionsFns[index]();
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <GoKebabHorizontal />
      </IconButton>
      <Menu id="menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem key={option} onClick={() => handleMenuItemClick(index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default RowDots;
