import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      "& > * + *": {
        marginLeft: "10px",
        color: "red",
      },
    },
  })
);

const Spinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        style={{
          color: "#3578e5",
        }}
        thickness={3.5}
        size={30}
      />
    </div>
  );
};

export { Spinner };
