import React from "react";

interface BaseButtonProps {
  children: any;
  extraPadding?: string;
  fullWidth?: boolean;
  onClick?: () => void;
  variant?: "danger" | "primary" | "mild" | "grey";
}

const BaseButtonOutline = ({
  children,
  extraPadding,
  fullWidth,
  onClick,
  variant = "primary",
}: BaseButtonProps) => {
  let borderColor, textColor;

  switch (variant) {
    case "danger":
      borderColor = "border-[#CC0000]";
      textColor = "text-[#CC0000]";
      break;
    case "primary":
      borderColor = "border-[#264FFA]";
      textColor = "text-[#264FFA]";
      break;
    case "mild":
      borderColor = "border-[#fff]";
      textColor = "text-[#fff]";
      break;
    case "grey":
      borderColor = "border-[#A0A0A0]";
      textColor = "text-[#A0A0A0]";
      break;
    default:
      borderColor = "border-[#264FFA]";
      textColor = "text-[#264FFA]";
  }

  const baseStyles =
    "relative inline-flex items-center justify-center gap-x-1.5 rounded-md border border-1 py-3 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500";

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${baseStyles} ${borderColor} ${textColor} ${
        extraPadding ?? "px-4"
      } ${fullWidth ? "w-full" : "w-auto"}`}
    >
      {children}
    </button>
  );
};

export { BaseButtonOutline };
