"use client";

import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import BaseButton from "../button/base-button";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { getToken } from "@/src/utils/storage";

const navigation = [
  // { name: "Make a claim", href: "#", current: true },
  { name: "Request a call back", href: "/request-call-back", current: false },
  { name: "Log in", href: "/log-in", current: false },
];

const authNavigation = [
  // { name: "Make a claim", href: "#", current: true },
  { name: "Request a call back", href: "/request-call-back", current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function MainNav() {
  const [nav, setNav] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = getToken();
      if (token) {
        setNav(true);
      } else {
        setNav(false);
      }
    }
  }, []);

  return (
    <Disclosure as="nav" className="bg-[#F1F1F1]">
      {({ open }) => (
        <>
          <div className="mx-auto sm:mx-auto lg:mx-[7%]  max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-black  hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <Link href="/">
                    <img
                      className="h-[120px] w-auto"
                      src="/svgs/logo_blue.svg"
                      alt="Your Company"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex items-center">
                {!nav ? (
                  <>
                    <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "border border-1 border-[#264FFA] text-[#264FFA]"
                              : "text-[#264FFA]",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="flex-shrink-0">
                      <BaseButton onClick={() => router.push("/sign-up")}>
                        Sign up
                      </BaseButton>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                      {authNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "border border-1 border-[#264FFA] text-[#264FFA]"
                              : "text-[#264FFA]",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="flex-shrink-0">
                      <BaseButton href="/home">Dashboard</BaseButton>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {!nav
                ? navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "border border-1 border-[#264FFA] text-[#264FFA]"
                          : "text-[#264FFA]",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))
                : authNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "border border-1 border-[#264FFA] text-[#264FFA]"
                          : "text-[#264FFA]",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
