import cookies from "next-cookies";
import router from "next/router";
import { jwtDecode } from "jwt-decode";

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  partnerId?: number;
  type: "super_admin" | "vendor";
  studyAbroadSubscriptionType: string;
}

export const TOKEN = "-edusko-session-token-";
export const USER_DATA = "-edusko-session-user-";

const isClient = typeof window !== "undefined";

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN, token);
};

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(TOKEN);
  }
};

export const clearToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.removeItem(TOKEN);
  }
};

export const saveUserInfoToStorage = (
  payload: Record<string, string | number | undefined>
) => {
  try {
    if (payload.token) {
      delete payload.token;
    }
    return localStorage.setItem(USER_DATA, JSON.stringify(payload));
  } catch (e) {
    return e;
  }
};

export const getUserInfoFromStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(USER_DATA) as string);
  } catch (e) {
    return null;
  }
};

export const clearStorage = () => {
  try {
    localStorage.remove(TOKEN);
    localStorage.remove(USER_DATA);
  } catch (e) {
    return e;
  }
};

export const handleFormStateChange = (
  fieldName: string,
  getValues: () => void,
  setValue: (name: string, value: any) => void,
  trigger: (value: any) => void,
  updateState?: (value: any) => void
) => {
  return (val: any) => {
    // Optionally update any state passed in
    updateState && updateState(val);

    // Update the form state
    setValue(fieldName, val.value);
    trigger(fieldName);
    sessionStorage.setItem("child-profile", JSON.stringify(getValues()));
  };
};

export const isTokenValid = (token: string) => {
  if (token) {
    const decodedToken: any = jwtDecode(token);
    return Date.now() <= decodedToken?.exp * 1000;
  }
  return false;
};

export const getSession = (ctx?: any) => {
  let inSession: boolean | undefined;
  let user: UserData;
  let token: any | undefined;

  function logout() {
    clearToken();
    clearStorage();
    typeof window !== "undefined" && router.push("/signin?redirect=true");
  }

  if (isClient) {
    token = getToken();
    inSession = isTokenValid(token as string);
    if (!inSession) {
      clearStorage();
    }
    user = getUserInfoFromStorage();
  } else {
    const myCookie = cookies(ctx || "");
    token = myCookie[TOKEN] as string;
    inSession = isTokenValid(token);
    user = myCookie[USER_DATA] as any;
  }

  return {
    inSession,
    user,
    logout,
    token: `Bearer ${token}`,
    isAdmin: user?.type === "super_admin",
  };
};
