"use client";

import React from "react";
import { Container } from "../../container";
import BaseButton from "../../button/base-button";
import { useRouter } from "next/navigation";
import { AnimatedTextGeneral } from "@/src/components/animated-text";

const ThirdSection = () => {
  const router = useRouter();

  const items = [
    {
      title: "Pay benefits for death",
      info: "Your child receives full savings plus 1% of the sum of your target savings, every month for the remaining period of the policy 🌟",
      image: "/images/firstitem.png",
    },
    {
      title: "In case of critical illness",
      info: "You can receive up to six months waiver of your monthly payment till you recover fully from the illness. 😊📚",
      image: "/images/seconditem.png",
    },
    {
      title: "In case of accident",
      info: "Your child receives full savings plus 1% of the sum of your target savings, every month for the remaining period of the policy. 😊📄💼",
      image: "/images/thirditem.png",
    },
    {
      title: "Cash Withdrawal",
      info: "You've got an option to get your savings back even before maturity of the plan. 🎉",
      image: "/images/fourthitem.png",
    },
  ];

  return (
    <div id="why-edgo" className="bg-[#e9edfe] px-5 text-[#000000]">
      <Container>
        <div className="text-center">
          <AnimatedTextGeneral
            textLarge={true}
            description="Why EdGo is a life saver."
            delay={50}
          />
        </div>
        <p className="text-[20px] text-center">
          We will guarantee the quality of education your child enjoys while
          you're still alive or otherwise.
        </p>
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-5"
        >
          {items.map((it) => (
            <li key={it.title} className="col-span-1 flex flex-col text-center">
              <div className="flex flex-1 flex-col p-8">
                <img
                  className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                  src={it.image}
                  alt=""
                />
                <h3 className="mt-6 text-xl font-medium text-gray-900">
                  {it.title}
                </h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                  <dt className="sr-only">Info</dt>
                  <dd className="mt-3">
                    <span className="inline-flex items-center  px-2 py-1 text-xs font-medium">
                      {it.info}
                    </span>
                  </dd>
                </dl>
              </div>
            </li>
          ))}
        </ul>
        <div className="w-full my-5 flex justify-center">
          <BaseButton
            onClick={() => router.push("/sign-up")}
            extraPadding="px-10"
          >
            Get started
          </BaseButton>
        </div>
      </Container>
    </div>
  );
};

export { ThirdSection };
