"use client";
import React, { useState } from "react";
import { Container } from "../../container";
import { CallBackModal } from "../../call-back-modal";

const HelpSection = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="bg-black">
      <Container>
        <div
          id="need-advice"
          className="flex items-center flex-col sm:flex-row md:flex-row lg:flex-row w-[100%] text-white mb-3"
        >
          <div className="sm:w-[100%] md:w-[100%] lg:w-[50%]">
            <img src="/images/helpsc.png" alt="" />
          </div>
          <div className="sm:w-[100%] md:w-[100%] lg:w-[50%]">
            <h1 className="text-3xl md:text-2xl lg:text-[2.5em] font-bold mb-5 sm:leading-tight md:leading-tight lg:leading-tight">
              Require some help?
            </h1>
            <p>call us on 09034683483</p>
            <p className="my-5">Or</p>
            <p
              onClick={() => setIsOpen(true)}
              className="underline cursor-pointer"
            >
              Request a call back
            </p>
          </div>
        </div>
      </Container>
      <CallBackModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export { HelpSection };
